import * as React from 'react';
import PageSeo from '../../components/Seo/PageSeo';
import styled from 'styled-components';
import PageSection from '../../components/PageSection';
import { HeroBanner3, ThreeColumnLayout, GradientCard, CalloutBanner } from '../../components';
import PhillIcon from '../../assets/images/phill_icon.svg';
import CapsulesIcon from '../../assets/images/icon-pills-capsules.png';
import SafetyIcon from '../../assets/images/safety-icon.svg';
import Reference from '../../components/Reference';

// SEO INFORMATION
const pageMeta = {
  title: 'WELIREG™ (belzutifan) for von Hippel-Lindau (VHL) Disease–associated Tumors',
  keywords: 'welireg for von hippel-lindau disease, belzutifan for von hippel-lindau disease',
  description:  'Health care professionals may find information about WELIREG™ (belzutifan) for von Hippel-Lindau (VHL) disease–associated tumors, including efficacy and safety data. Explore here.',
  schemaJsonLD: [
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/","@type":"ApprovedIndication","name":"WELIREG™","alternateName":"belzutifan","description":"WELIREG is indicated for the treatment of adult patients with von Hippel-Lindau (VHL) disease who require therapy for associated renal cell carcinoma (RCC), central nervous system (CNS) hemangioblastomas, or pancreatic neuroendocrine tumors (pNET), not requiring immediate surgery."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
    `{"@context":"https://schema.org/","url":"https://www.welireghcp.com/von-hippel-lindau/","@type":"Drug","proprietaryName":"WELIREG™","nonProprietaryName":"belzutifan","manufacturer":"Merck"}`
  ]
}

const VHLIndex3Col = styled(ThreeColumnLayout)`
  a {

    &:nth-child(2) {
      padding-right: 20px;
    }
    &:after {
      display: none;
    }
  }

`;

const cardsData = [
  {
    icon: CapsulesIcon,
    title: 'Dosing for WELIREG',
    description: 'Information on dosing, including recommended dose reductions and dose modifications for adverse reactions.',
    linkText: 'See Dosing Details',
    linkUrl: '/von-hippel-lindau/dosing',
  },
  {
    icon: PhillIcon,
    bcbContinual: true,
    title: 'The Merck Access Program',
    description: <>Enroll your patients to <b>start on WELIREG, initiate access, and confirm coverage</b> via The Merck Access Program.</>,
    linkText: 'View Now',
    linkUrl: 'https://www.askmerck.com/',
    overrideNoIcon: true,

  },
  {
    icon: SafetyIcon,
    title: 'Safety Profile',
    description: 'See safety information, including adverse reactions and laboratory abnormalities in patients treated with WELIREG.',
    linkText: 'View Safety Data',
    linkUrl: '/von-hippel-lindau/adverse-reaction-profile/',
  },
];

const calloutBanner = {
  title: <>Consider WELIREG for your appropriate patients with certain VHL disease&#8288;–&#8288;associated tumors</>,
  bcbContinual: true,
  links: [
    {
      label: 'See Study Design',
      url: '/von-hippel-lindau/efficacy/#Study-Design',
    },
  ],
};

const eyeBrow = <>FOR APPROPRIATE PATIENTS WHO DO NOT REQUIRE IMMEDIATE SURGERY</>;
const heading1 = <>TO TREATING CERTAIN VHL-ASSOCIATED TUMORS<sup>1</sup></>;
const heading2 = <></>;
const description = <>WELIREG is indicated for the treatment of adult patients with von Hippel-Lindau (VHL) disease who require therapy for associated renal cell carcinoma (RCC), central nervous system (CNS) hemangioblastomas, or pancreatic neuroendocrine tumors (pNET), not requiring immediate surgery.</>;
const bannerText = <></>;
const footnotes = [

];

const IndexPage = () => {
  return (
    <>
      <PageSeo pageMeta={pageMeta} >
        <link rel="canonical" href="https://www.welireghcp.com/von-hippel-lindau/" />
      </PageSeo>

      <HeroBanner3 linkUrl='/von-hippel-lindau/efficacy' linkText='Explore the Efficacy'
        eyeBrow={eyeBrow}
        heading1={heading1}
        heading2={heading2}
        description={description}
        bannerText={bannerText}
        footnotes={footnotes}
      />

      <VHLIndex3Col>
        {
          cardsData.map((card, index) => (
            <GradientCard {...card} key={index} icon={card.icon} title={card.title} description={card.description} linkText={card.linkText} linkUrl={card.linkUrl} />
          ))
        }
      </VHLIndex3Col>

      <PageSection>
        <Reference data={['Narayan V, Jonasch E. Systemic therapy development in von Hippel-Lindau disease: an outsized contribution from an orphan disease. <em>Cancers (Basel)</em>. 2022;14(21):5313.', 'Center for Drug Evaluation and Research. <em>Advancing Health Through Innovation: New Drug Therapy Approvals 2021</em>. https://www.fda.gov/media/155227/download. Accessed February 6, 2024.']} />
      </PageSection>

      <CalloutBanner {...calloutBanner} />
    </>
  );
};

export default IndexPage;
